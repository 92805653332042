#bar {
  text-align: center;
  width: 700px;
  height: 25px;
  background-color: #e4e3e5;
  margin: 0 auto;
  font-family: monospace;
  padding: auto;
  float: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#button-red {
  background-color: #f96256;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  left: -47%;
  bottom: -20%;
  position:relative;
}

#button-yellow {
  background-color: #fdbc3d;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  left: -44%;
  bottom: 40%;
  position:relative;
  display: block;
}

#button-green {
  background-color: #33c948;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  left: -41%;
  bottom: 99%;
  position:relative;
  display: block;
}

#screen {
  background-color: rgb(66, 66, 66);
  width: 700px;
  height: 270px;
  border-radius: 0 0 4px 4px;
  margin: 0 auto;
  padding-top: 20px;

box-shadow: 0px 20px 100px rgba(50,50,93,.15), 0 15px 35px rgba(50,50,93,.2), 0 5px 15px rgba(0,0,0,.12);
}

#screen .command {
  margin: 0;
  color: #FFFFFF;
  font-family: monospace;
  font-size: 13px;
  text-align: left;
  position: static;
  padding-left: 30px
}

#screen .command::before {
content: "> ";
}

#screen .response {
  margin: 0 0 25px 0;
  color: #FBDB5A;
  font-family: monospace;
  font-size: 13px;
  text-align: left;
  position: static;
  padding-left: 30px;
}

#screen .array::before {
content: "[";
}

#screen .array::after {
content: "]";
}

#screen .array .array-element::before {
content: "\"";
}

#screen .array .array-element::after {
content: "\"";
}

#screen a {
background-color: #5DB96F;
color: #FBDB5A;
text-decoration: none;
}

#screen a:hover {
color: #FFFFFF;
}

#screen .cursor {
background: hsla(0, 0%, 97%, .65);
animation: blinker 1s linear infinite;
}

@keyframes blinker {  
50% { opacity: 0; }
}

.preformatted {
  white-space: pre;
}